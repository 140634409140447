import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Spacer,
} from '@chakra-ui/react';
import { useState, useEffect, useRef } from 'react';

import ThemeSelector from '../../components/themeSelector';
import Loading from '../../components/Loading';
import { msgAtencao, tratarErro } from '../../helpers/funcoes';
import Button from '../../components/diversos/button';
import Logo from '../../components/Logo';
import { usePostLoginCpfMutation } from '../../redux/api/authApi';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    let navigate = useNavigate();
    const [postData, { error }] = usePostLoginCpfMutation();
    const [cpf, setCpf] = useState('');
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);

    const iCpf = useRef<HTMLInputElement>(null);
    const iSenha = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (error === undefined) return;
        tratarErro(error);
    }, [error]);

    const handleLogin = async (e: any) => {
        e.preventDefault();

        if (cpf.trim() === '') {
            msgAtencao('Favor informar o CPF/CNPJ.');
            if (iCpf.current) iCpf.current.focus();
            return;
        }

        if (senha.trim() === '') {
            msgAtencao('Favor informar a senha.');
            if (iSenha.current) iSenha.current.focus();
            return;
        }
        setLoading(true);
        postData({ cpf, senha })
            .unwrap()
            .then(() => {
                setLoading(false);
                navigate('/', { replace: true });
            })
            .catch((e) => {
                setLoading(false);
                console.log('ERRO LOGIN', e);
            });

        //navigate('/', { replace: true });
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            handleLogin(e);
        }
    };

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
        >
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Logo />
                    </Box>
                    <Box my={8} textAlign="left">
                        <form>
                            <FormControl>
                                <FormLabel>CPF/CNPJ</FormLabel>
                                <Input
                                    type="text"
                                    placeholder="Informe o CPF/CNPJ do conveniado"
                                    value={cpf}
                                    onChange={(e: any) => {
                                        setCpf(e.target.value);
                                    }}
                                    ref={iCpf}
                                />
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Senha</FormLabel>
                                <Input
                                    placeholder="Informe a senha"
                                    maxLength={20}
                                    value={senha}
                                    onChange={(e: any) => {
                                        setSenha(e.target.value);
                                    }}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    autoComplete="off"
                                    type="password"
                                    ref={iSenha}
                                />
                            </FormControl>

                            <Button
                                width="full"
                                mt={4}
                                disabled={loading}
                                onClick={handleLogin}
                            >
                                {loading && <Loading />}
                                <span>Entrar</span>
                            </Button>
                            <Box
                                marginTop={4}
                                display={'flex'}
                                flexDir={'row'}
                                alignItems={'center'}
                                alignContent={'space-between'}
                            >
                                <a href="/alterar">
                                    <Text
                                        fontSize="md"
                                        fontWeight={'bold'}
                                        ml={2}
                                    >
                                        Esqueceu a senha?
                                    </Text>
                                </a>
                                <Spacer />
                                <a href="/novoconveniado">
                                    <Button mr={2}>
                                        <span>Novo</span>
                                    </Button>
                                </a>
                                <ThemeSelector />
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default Login;

