import { baseApi } from './baseApi';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['Login'] });

export const authApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        postLoginCpf: builder.mutation<any, { cpf: string; senha: string }>({
            query({ cpf, senha }) {
                let url = `conveniados/login`;
                return {
                    url: url,
                    method: 'POST',
                    //credentials: 'include',
                    body: { cpf, senha },
                };
            },
            async onQueryStarted({ cpf, senha }, { dispatch, queryFulfilled }) {
                try {
                    const { data: updatedPost } = await queryFulfilled;
                    const dados = { ...updatedPost, horaLogin: new Date() };
                    sessionStorage.setItem('conveniado', JSON.stringify(dados));
                } catch {}
            },
        }),
        logoutUser: builder.mutation<void, void>({
            query() {
                return {
                    url: 'ping',
                    method: 'GET',
                };
            },
            async onQueryStarted(_, { dispatch }) {
                dispatch(baseApi.util.resetApiState());
                sessionStorage.clear();
            },
        }),
    }),
    overrideExisting: true,
});

export const { usePostLoginCpfMutation, useLogoutUserMutation } = authApi;
