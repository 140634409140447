import { useState, useEffect } from 'react';
import FormCadastro from '../../components/Cadastro';
import { getConveniado, msgErro } from '../../helpers/funcoes';
import { useLazyGetConveniadoQuery } from '../../redux/api/conveniadoApi';

const Cadastro = () => {
    const [getConveniadoRedux] = useLazyGetConveniadoQuery();
    const [dadosConveniado, setDadosConveniado] = useState<retornoConveniado>();

    useEffect(() => {
        const conveniado = getConveniado();
        if (conveniado !== undefined) {
            getConveniadoRedux(conveniado.id)
                .unwrap()
                .then((dados: retornoConveniado) => {
                    setDadosConveniado(dados);
                })
                .catch((error: any) => {
                    let mensagem = '';
                    if (error.response.data)
                        mensagem = error.response.data.error;
                    else
                        mensagem =
                            'Um erro inesperado ocorreu ao localizar os dados do CEP.';
                    msgErro(mensagem);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <FormCadastro conveniado={dadosConveniado} />;
};

export default Cadastro;
