import { ColorModeScript } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { baseApi } from './redux/api/baseApi';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
    <ApiProvider api={baseApi}>
        <BrowserRouter>
            <ColorModeScript />
            <App />
        </BrowserRouter>
    </ApiProvider>
);

