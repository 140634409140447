/* eslint-disable no-unused-vars */
import { useState, useRef } from 'react';
import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Image,
    Input,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { msgAtencao, msgErro, msgSucesso } from '../../helpers/funcoes';
import axiosApi from '../../helpers/axios';
import Button from '../../components/diversos/button';
import Loading from '../../components/Loading';
import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';

const Resetar = () => {
    const logoUsado = useColorModeValue(logo, logoBranco);
    const [item, setItem] = useState({
        cpf: '',
        senha: '',
        confirmarSenha: '',
    });
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const cpf = useRef<HTMLInputElement>(null);
    const senha = useRef<HTMLInputElement>(null);
    const confirmarSenha = useRef<HTMLInputElement>(null);

    const [chave] = useState(
        new URLSearchParams(window.location.search).get('chave')
    );

    let history = useNavigate();

    if (!chave) {
        history('/');
    }

    function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cpf') {
            const valor = evt.target.validity.valid
                ? evt.target.value
                : item.cpf;
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else {
            itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        }
        setItem(itemAux);
    }

    const handleAlterar = (e: any) => {
        e.preventDefault();
        if (item.cpf && item.cpf.trim() === '') {
            msgAtencao('Favor informar o CPF/CNPJ.');
            if (cpf.current) cpf.current.focus();
            return;
        }
        if (item.senha.trim() === '') {
            msgAtencao('Favor informar a senha.');
            if (senha.current) senha.current.focus();
            return;
        }
        if (item.confirmarSenha.trim() === '') {
            msgAtencao('Favor informar a confirmação de senha.');
            if (confirmarSenha.current) confirmarSenha.current.focus();
            return;
        }
        if (item.senha !== item.confirmarSenha) {
            msgAtencao(
                'A confirmação de senha não corresponde a senha informada'
            );
            if (senha.current) senha.current.focus();
            return;
        }
        setLoading(true);
        setErro('');
        let itemEnviar = { ...item, chave: chave };
        axiosApi
            .put('/conveniados/alterar', itemEnviar)
            .then(() => {
                setLoading(false);
                msgSucesso('Alteração de senha realizada com sucesso.');
                setItem({
                    cpf: '',
                    senha: '',
                    confirmarSenha: '',
                });
                setTimeout(() => {
                    history('/login');
                }, 3000);
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = '';
                if (error.response.data) mensagem = error.response.data.error;
                else
                    mensagem =
                        'Não foi possível realizar a alteração da senha.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    };

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
        >
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Image src={logoUsado} />
                    </Box>
                    <Box my={8} textAlign="left">
                        <form>
                            <FormControl mt={4} id="cpf">
                                <FormLabel>CPF/CNPJ</FormLabel>
                                <Input
                                    placeholder="Informe o CPF/CNPJ"
                                    name="cpf"
                                    maxLength={14}
                                    value={item.cpf}
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    type="text"
                                    pattern="[0-9]*"
                                    ref={cpf}
                                />
                            </FormControl>

                            <FormControl id="senha">
                                <FormLabel>Senha</FormLabel>
                                <Input
                                    type="password"
                                    name="senha"
                                    maxLength={20}
                                    placeholder="Informe a nova senha"
                                    value={item.senha}
                                    onChange={(e) => handleChange(e)}
                                    ref={senha}
                                />
                            </FormControl>

                            <FormControl id="confirmarSenha">
                                <FormLabel>Confirmação de Senha</FormLabel>
                                <Input
                                    type="password"
                                    name="confirmarSenha"
                                    maxLength={20}
                                    placeholder="Confirme a nova senha"
                                    value={item.confirmarSenha}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>

                            {erro ? (
                                <Box color="red" fontWeight="bold">
                                    <Text>{erro}</Text>
                                </Box>
                            ) : null}
                            <Button
                                mt={4}
                                mr={4}
                                disabled={loading}
                                onClick={handleAlterar}
                                w="150px"
                            >
                                {loading && <Loading />}
                                <span>Alterar</span>
                            </Button>
                            <Button
                                mt={4}
                                onClick={() => history('/login')}
                                w="150px"
                                disabled={loading}
                            >
                                <span>Login</span>
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default Resetar;
