import {
    Button as Botao,
    ButtonProps,
    useColorModeValue,
} from '@chakra-ui/react';

const Button = (props: ButtonProps) => {
    console.log('PROPS', props);
    return (
        <Botao
            {...props}
            bg={useColorModeValue('teal.500', 'gray.600')}
            color="white"
            _hover={{
                backgroundColor: useColorModeValue('teal.700', 'gray.900'),
            }}
        >
            {props.children}
        </Botao>
    );
};

export default Button;
