import { Routes, Route, Navigate } from 'react-router-dom';
import { chaveValida, getConveniado } from './helpers/funcoes';
import Login from './pages/Login';
import Cadastro from './pages/Cadastro';
import Alterar from './pages/Alterar';
import Resetar from './pages/Resetar';

const PrivateWrapper = ({ children }: { children: JSX.Element }) => {
    const conveniado = getConveniado();
    const logado = conveniado !== undefined && chaveValida();
    return logado ? children : <Navigate to="/login" replace />;
};

const Rotas = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateWrapper>
                        <Cadastro />
                    </PrivateWrapper>
                }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/novoconveniado" element={<Cadastro />} />
            <Route path="/alterar" element={<Alterar />} />
            <Route path="/resetar" element={<Resetar />} />
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
};

export default Rotas;
