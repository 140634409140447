import axios from 'axios';
// eslint-disable-next-line no-undef
const API_URL = process.env.REACT_APP_URI;
const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.interceptors.request.use(function (config: any) {
    const valor = sessionStorage.getItem('conveniado');
    if (valor) {
        const conveniado = JSON.parse(valor || '');
        if (conveniado && conveniado.token)
            config.headers.Authorization = 'Bearer ' + conveniado.token;
    }
    config.headers['x-cardassi-x'] = '4';
    return config;
});

export default axiosApi;

