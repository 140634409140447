import { useColorModeValue, Image } from '@chakra-ui/react';
import logo from '../../assets/logoazul.png';
import logoBranco from '../../assets/logobranco.png';

const Logo = () => {
    const logoUsado = useColorModeValue(logo, logoBranco);
    return <Image src={logoUsado} />;
};

export default Logo;
