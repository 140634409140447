import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { useState, useRef } from 'react';
import Loading from '../../components/Loading';
import Button from '../../components/diversos/button';
import { msgAtencao, msgErro, msgSucesso } from '../../helpers/funcoes';
import axiosApi from '../../helpers/axios';
import Logo from '../../components/Logo';
import { InfoIcon } from '@chakra-ui/icons';

const Alterar = () => {
    const [item, setItem] = useState({
        cpf: '',
        telefone: '',
        email: '',
    });
    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);
    const cpf = useRef<HTMLInputElement>(null);
    const email = useRef<HTMLInputElement>(null);

    function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cpf') {
            const valor = evt.target.validity.valid
                ? evt.target.value
                : item.cpf;
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else {
            itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        }
        setItem(itemAux);
    }

    const handleAlterar = (e: any) => {
        e.preventDefault();
        if (item.cpf && item.cpf.trim() === '') {
            msgAtencao('Favor informar o CPF/CNPJ.');
            if (cpf.current) {
                cpf.current.focus();
            }
            return;
        }

        if (item.email.trim() === '' && item.telefone.trim() === '') {
            msgAtencao('Favor informar e-mail ou telefone.');
            if (email.current) email.current.focus();
            return;
        }
        setLoading(true);
        setErro('');
        axiosApi
            .post('/conveniados/alterar', item)
            .then(() => {
                setLoading(false);
                msgSucesso(
                    'Solicitação para alteração de senha realizada com sucesso.'
                );
                setItem({
                    cpf: '',
                    telefone: '',
                    email: '',
                });
            })
            .catch((error) => {
                setLoading(false);
                let mensagem = '';
                if (error.response) mensagem = error.response.data.error;
                else
                    mensagem =
                        'Não foi possível realizar a alteração da senha.';
                setErro(mensagem);
                msgErro(mensagem);
            });
    };

    return (
        <Flex
            minHeight="100vh"
            width="full"
            align="center"
            justifyContent="center"
        >
            <Box
                px={4}
                width="full"
                maxWidth={{ lg: '500px', xl: '500px', base: '90%' }}
                borderRadius={4}
                textAlign="center"
                boxShadow="dark-lg"
            >
                <Box p={4}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Logo />
                    </Box>
                    <Box justifyContent="center" alignItems="center">
                        <Text fontSize="2xl" fontWeight="500">
                            Resetar Senha
                        </Text>
                    </Box>
                    <Box my={4} textAlign="left">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleAlterar(e);
                            }}
                        >
                            <FormControl mt={4} id="cpf">
                                <FormLabel>CPF/CNPJ</FormLabel>
                                <Input
                                    placeholder="Informe o CPF/CNPJ"
                                    name="cpf"
                                    maxLength={14}
                                    value={item.cpf}
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    type="text"
                                    pattern="[0-9]*"
                                    ref={cpf}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>
                                    <span>E-mail</span>
                                    <Tooltip
                                        label="Informe e-mail e/ou WhatsApp"
                                        fontSize="md"
                                    >
                                        <InfoIcon ml={2} />
                                    </Tooltip>
                                </FormLabel>
                                <Input
                                    type="text"
                                    name="email"
                                    maxLength={100}
                                    placeholder="Informe o E-mail"
                                    value={item.email}
                                    onChange={(e) => handleChange(e)}
                                    ref={email}
                                />
                            </FormControl>

                            <FormControl id="telefone">
                                <FormLabel>Telefone (WhatsApp)</FormLabel>
                                <Input
                                    type="text"
                                    name="telefone"
                                    placeholder="(XX) XXXXX-XXXXX"
                                    maxLength={20}
                                    value={item.telefone}
                                    onChange={(e) => handleChange(e)}
                                />
                            </FormControl>
                            {erro ? (
                                <Box color="red" fontWeight="bold">
                                    <Text>{erro}</Text>
                                </Box>
                            ) : null}
                            <Button
                                mt={4}
                                mr={4}
                                disabled={loading}
                                onClick={handleAlterar}
                                w="150px"
                            >
                                {loading && <Loading />}
                                <span>Criar/Alterar</span>
                            </Button>
                            <Button
                                mt={4}
                                onClick={() => window.location.replace('/')}
                                w="150px"
                                disabled={loading}
                            >
                                <span>Voltar</span>
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
};

export default Alterar;
