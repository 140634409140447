import {
    Box,
    useColorModeValue,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerBody,
} from '@chakra-ui/react';
import NavLink from '../NavLink';
import ThemeSelector from '../themeSelector';
import Button from '../diversos/button';
import { HamburgerIcon } from '@chakra-ui/icons';
import Logo from '../Logo';
import { useLogoutUserMutation } from '../../redux/api/authApi';
import { getConveniado } from '../../helpers/funcoes';

const Header = () => {
    const conveniado = getConveniado();
    const [postData] = useLogoutUserMutation();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const sombra = useColorModeValue('lg', 'dark-lg');
    const bg = useColorModeValue('white', 'gray.800');
    const bgMenu = useColorModeValue('gray.50', 'gray.700');

    const sair = async () => {
        await postData();
        window.location.replace('/login');
    };

    const menuGrande = () => {
        return (
            <Box
                display={{ lg: 'flex', xl: 'flex', base: 'none' }}
                backgroundColor={bgMenu}
                mx={{
                    lg: -3,
                    xl: -3,
                    base: -1,
                }}
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
            >
                {conveniado !== undefined ? (
                    <>
                        <NavLink to="#" onClick={sair}>
                            Sair
                        </NavLink>
                    </>
                ) : null}
            </Box>
        );
    };

    const menuCompacto = () => {
        return (
            <Drawer
                placement="left"
                onClose={onClose}
                isOpen={isOpen}
                //@ts-ignore
                display={{ lg: 'none', xl: 'none' }}
            >
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerBody>
                            {conveniado !== undefined ? (
                                <>
                                    <NavLink to="#" onClick={sair}>
                                        Sair
                                    </NavLink>
                                    <Box>
                                        <ThemeSelector />
                                    </Box>
                                </>
                            ) : null}
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        );
    };

    return (
        <Box
            boxShadow={sombra}
            borderRadius={4}
            px={{
                lg: 3,
                xl: 3,
                base: 1,
            }}
            position={{ lg: 'sticky', xl: 'sticky', base: undefined }}
            top="0"
            zIndex={999}
            backgroundColor={bg}
            mb={3}
        >
            <>
                <Box
                    p="3"
                    display="flex"
                    justifyContent={{
                        lg: 'left',
                        xl: 'left',
                        base: 'center',
                    }}
                >
                    <Logo />
                    <Box
                        w="100%"
                        flexDir="row-reverse"
                        display={{
                            lg: 'flex',
                            xl: 'flex',
                            base: 'none',
                        }}
                    >
                        <ThemeSelector />
                    </Box>
                </Box>
                {menuGrande()}
                {menuCompacto()}
                {conveniado !== undefined ? (
                    <Button
                        onClick={onOpen}
                        display={{ lg: 'none', xl: 'none' }}
                        mb={2}
                    >
                        <HamburgerIcon w={6} h={6} />
                    </Button>
                ) : null}
            </>
        </Box>
    );
};

export default Header;
