import { ChakraProvider, theme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { ToastContainer } from 'react-toastify';

import Rotas from './rotas';

const GlobalStyles = css`
    /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

export const App = () => (
    <ChakraProvider theme={theme}>
        <Global styles={GlobalStyles} />

        <ToastContainer />
        <Rotas />
    </ChakraProvider>
);

