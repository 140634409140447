import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseUrl = process.env.REACT_APP_URI;

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            headers.set('x-cardassi-x', '4');
            const storage = sessionStorage.getItem('conveniado') || '';
            if (storage !== '') {
                const conveniado = JSON.parse(storage);
                if (conveniado.token) {
                    headers.set('authorization', `Bearer ${conveniado.token}`);
                }
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 180,
    endpoints: () => ({}),
});
