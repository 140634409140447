import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Switch,
    Text,
    Textarea,
    Tooltip,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import Button from '../diversos/button';
import {
    useGetEspecialidadesQuery,
    useLazyGetCEPQuery,
} from '../../redux/api/conveniadoApi';
import {
    getConveniado,
    msgAtencao,
    msgErro,
    msgSucesso,
} from '../../helpers/funcoes';
import axiosApi from '../../helpers/axios';
import { InfoIcon } from '@chakra-ui/icons';
import Loading from '../Loading';
import Base from '../base';

const ufs = [
    { value: 'AC', label: 'AC' },
    { value: 'AL', label: 'AL' },
    { value: 'AP', label: 'AP' },
    { value: 'AM', label: 'AM' },
    { value: 'BA', label: 'BA' },
    { value: 'CE', label: 'CE' },
    { value: 'DF', label: 'DF' },
    { value: 'ES', label: 'ES' },
    { value: 'GO', label: 'GO' },
    { value: 'MA', label: 'MA' },
    { value: 'MS', label: 'MS' },
    { value: 'MT', label: 'MT' },
    { value: 'MG', label: 'MG' },
    { value: 'PA', label: 'PA' },
    { value: 'PB', label: 'PB' },
    { value: 'PR', label: 'PR' },
    { value: 'PE', label: 'PE' },
    { value: 'PI', label: 'PI' },
    { value: 'RJ', label: 'RJ' },
    { value: 'RN', label: 'RN' },
    { value: 'RS', label: 'RS' },
    { value: 'RO', label: 'RO' },
    { value: 'RR', label: 'RR' },
    { value: 'SC', label: 'SC' },
    { value: 'SP', label: 'SP' },
    { value: 'SE', label: 'SE' },
    { value: 'TO', label: 'TO' },
];

const FormCadastro = ({ conveniado: conveniadoApi }: IconveniadoApi) => {
    const conveniado = getConveniado();
    const { data: especialidades } = useGetEspecialidadesQuery();

    const [getCep] = useLazyGetCEPQuery();

    const [especialidadesSel, setEspecialidadesSel] = useState<
        readonly OpcoesSelect[]
    >([]);

    const [uf, setUf] = useState<OpcoesSelect | null>();
    const [aceite, setAceite] = useState(false);

    const [item, setItem] = useState({
        id: 0,
        cpf: '',
        nome: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        codcidade: '',
        telefone: '',
        email: '',
        senha: '',
        vantagens: '',
        exibirOnline: 1,
        observacao: '',
    });

    const [erro, setErro] = useState('');
    const [loading, setLoading] = useState(false);

    const cpf = useRef<HTMLInputElement>(null);
    const nome = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (conveniadoApi === undefined) return;
        setItem({
            id: conveniadoApi.id,
            cpf: conveniadoApi.cpf,
            nome: conveniadoApi.nome,
            cep: conveniadoApi.cep,
            logradouro: conveniadoApi.logradouro,
            numero: conveniadoApi.numero,
            complemento: conveniadoApi.complemento,
            bairro: conveniadoApi.bairro,
            cidade: conveniadoApi.cidade,
            codcidade: conveniadoApi.codcidade,
            telefone: conveniadoApi.telefone.join('\n'),
            email: conveniadoApi.email,
            senha: '',
            vantagens: conveniadoApi.vantagens,
            exibirOnline: conveniadoApi.exibirOnline,
            observacao: conveniadoApi.observacao,
        });
        setUf({ value: conveniadoApi.uf, label: conveniadoApi.uf });
        const especialidadesApi: OpcoesSelect[] = [];
        conveniadoApi.especialidades.map((item: any) => {
            return especialidadesApi.push({
                value: item.id,
                label: item.descricao,
            });
        });
        setEspecialidadesSel(especialidadesApi);
    }, [conveniadoApi]);

    useEffect(() => {
        console.log(
            'ACEITE',
            aceite,
            conveniado,
            loading || (conveniado === undefined && aceite === false)
        );
    }, [aceite]);

    async function handleChange(evt: any) {
        let itemAux = item;
        if (evt.target.name === 'cpf') {
            const valor = evt.target.validity.valid
                ? evt.target.value
                : item.cpf;
            itemAux = { ...itemAux, [evt.target.name]: valor };
        } else if (evt.target.name === 'cep') {
            itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
            if (itemAux.cep.length === 8) {
                await getCep(itemAux.cep)
                    .unwrap()
                    .then((dados: retornoCEP) => {
                        itemAux.bairro = dados.bairro;
                        itemAux.cidade = dados.cidade;
                        itemAux.codcidade = dados.codcidade;
                        setUf({ value: dados.uf, label: dados.uf });
                        itemAux.logradouro = dados.logradouro;
                    })
                    .catch((error: any) => {
                        let mensagem = '';
                        if (error.response.data)
                            mensagem = error.response.data.error;
                        else
                            mensagem =
                                'Um erro inesperado ocorreu ao localizar os dados do CEP.';
                        msgErro(mensagem);
                    });
            }
        } else {
            itemAux = { ...itemAux, [evt.target.name]: evt.target.value };
        }
        setItem(itemAux);
    }

    const handleCadastro = (e: any) => {
        e.preventDefault();
        if (item.cpf && item.cpf.trim() === '') {
            msgAtencao('Favor informar o CPF/CNPJ.');
            if (cpf.current) cpf.current.focus();
            return;
        }

        if (item.nome.trim() === '') {
            msgAtencao('Favor informar o nome.');
            if (nome.current) nome.current.focus();
            return;
        }

        if (item.cep.trim() === '') {
            msgAtencao('Favor informar o CEP.');
            return;
        }

        if (item.logradouro.trim() === '') {
            msgAtencao('Favor informar o Logradouro.');
            return;
        }

        if (item.numero.trim() === '') {
            msgAtencao('Favor informar o Número.');
            return;
        }

        if (item.bairro.trim() === '') {
            msgAtencao('Favor informar o Bairro.');
            return;
        }

        if (item.cidade.trim() === '') {
            msgAtencao('Favor informar a Cidade.');
            return;
        }

        if (uf?.value === '') {
            msgAtencao('Favor informar a UF.');
            return;
        }

        if (item.email.trim() === '') {
            msgAtencao('Favor informar o E-Mail.');
            return;
        }

        if (especialidadesSel.length === 0) {
            msgAtencao('Favor informar as especialidades.');
            return;
        }

        if (item.telefone.trim() === '') {
            msgAtencao('Favor informar o telefone.');
            return;
        }

        if (item.vantagens.trim() === '') {
            msgAtencao('Favor informar as vantagens.');
            return;
        }

        if (conveniado === undefined && item.senha.trim() === '') {
            msgAtencao('Favor informar a senha.');
            return;
        }

        if (conveniado === undefined && item.senha.trim().length < 6) {
            msgAtencao('A senha deve ter ao menos seis caracteres.');
            return;
        }

        if (conveniado === undefined && !aceite) {
            msgAtencao('Necessário aceitar os termos para prosseguir.');
            return;
        }

        setLoading(true);

        let dados = {
            ...item,
            telefone: item.telefone.split('\n'),
            uf: uf?.value,
            especialidades: especialidadesSel.map((sel) => sel.value),
            aceite: aceite,
        };

        setLoading(false);

        if (conveniado === undefined) {
            axiosApi
                .post('/conveniados', dados)
                .then(() => {
                    setLoading(false);
                    msgSucesso(
                        'Os dados foram enviados para análise. Em breve entraremos em contato.'
                    );
                    setTimeout(() => {
                        window.location.replace('/login');
                    }, 3000);
                })
                .catch((error) => {
                    setLoading(false);
                    let mensagem = '';
                    if (error.response.data)
                        mensagem = error.response.data.error;
                    else mensagem = 'Não foi possível realizar o cadastro.';
                    setErro(mensagem);
                    msgErro(mensagem);
                });
        } else {
            axiosApi
                .put(`/auth/conveniados/${dados.id}`, dados)
                .then(() => {
                    setLoading(false);
                    msgSucesso('Os dados foram enviados para análise.');
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                })
                .catch((error) => {
                    setLoading(false);
                    let mensagem = '';
                    if (error.response.data)
                        mensagem = error.response.data.error;
                    else mensagem = 'Não foi possível alterar o cadastro.';
                    setErro(mensagem);
                    msgErro(mensagem);
                });
        }
    };

    return (
        <Base>
            <Flex
                px={{
                    lg: 3,
                    xl: 3,
                    base: 1,
                }}
                flex="1"
                w="100%"
                mt={2}
                pb={3}
            >
                <Box as="form" id="frmDados" w="100%" /*maxW="700px"*/>
                    <Grid templateColumns="repeat(12, 1fr)" w="100%" gap="10px">
                        <GridItem
                            colSpan={{
                                lg: 4,
                                xl: 4,
                                base: 8,
                            }}
                        >
                            <FormControl id="cpf">
                                <FormLabel>CPF/CNPJ</FormLabel>
                                <Input
                                    name="cpf"
                                    value={item.cpf}
                                    onChange={(e) => handleChange(e)}
                                    ref={cpf}
                                    maxLength={14}
                                    type="text"
                                    pattern="[0-9]*"
                                    autoComplete="off"
                                    disabled={conveniado !== undefined}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 8,
                                xl: 8,
                                base: 12,
                            }}
                        >
                            <FormControl id="nome">
                                <FormLabel>Nome</FormLabel>
                                <Input
                                    name="nome"
                                    value={item.nome}
                                    onChange={(e) => handleChange(e)}
                                    ref={nome}
                                    maxLength={60}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 3,
                                xl: 3,
                                base: 4,
                            }}
                        >
                            <FormControl id="cep">
                                <FormLabel>CEP</FormLabel>
                                <Input
                                    name="cep"
                                    value={item.cep}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={8}
                                    type="text"
                                    pattern="[0-9]*"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 9,
                                xl: 9,
                                base: 8,
                            }}
                        >
                            <FormControl id="logradouro">
                                <FormLabel>Logradouro</FormLabel>
                                <Input
                                    name="logradouro"
                                    value={item.logradouro}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={60}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 3,
                                xl: 3,
                                base: 4,
                            }}
                        >
                            <FormControl id="numero">
                                <FormLabel>Número</FormLabel>
                                <Input
                                    name="numero"
                                    value={item.numero}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={10}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 9,
                                xl: 9,
                                base: 8,
                            }}
                        >
                            <FormControl id="complemento">
                                <FormLabel>Complemento</FormLabel>
                                <Input
                                    name="complemento"
                                    value={item.complemento}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={40}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 4,
                                xl: 4,
                                base: 12,
                            }}
                        >
                            <FormControl id="bairro">
                                <FormLabel>Bairro</FormLabel>
                                <Input
                                    name="bairro"
                                    value={item.bairro}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={40}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 5,
                                xl: 5,
                                base: 12,
                            }}
                        >
                            <FormControl id="cidade">
                                <FormLabel>Cidade</FormLabel>
                                <Input
                                    name="cidade"
                                    value={item.cidade}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={100}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 3,
                                xl: 3,
                                base: 6,
                            }}
                        >
                            <FormControl id="uf">
                                <FormLabel>UF</FormLabel>
                                <Select
                                    options={ufs}
                                    placeholder="Selecione"
                                    value={uf}
                                    onChange={setUf}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={12}>
                            <FormControl id="email">
                                <FormLabel>E-mail</FormLabel>
                                <Input
                                    name="email"
                                    value={item.email}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={100}
                                    type="text"
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={12}>
                            <FormControl id="especialidades">
                                <FormLabel>
                                    Selecione a(s) especialidade(s)
                                </FormLabel>
                                <Select
                                    isMulti
                                    options={especialidades}
                                    placeholder="Selecione alguma especialidade"
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    value={especialidadesSel}
                                    onChange={setEspecialidadesSel}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 6,
                                xl: 6,
                                base: 12,
                            }}
                        >
                            <FormControl id="telefone2">
                                <FormLabel>Telefones (1 por linha)</FormLabel>
                                <Textarea
                                    name="telefone"
                                    value={item.telefone}
                                    onChange={(e) => handleChange(e)}
                                    resize={'none'}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            colSpan={{
                                lg: 6,
                                xl: 6,
                                base: 12,
                            }}
                        >
                            <FormControl id="vantagens">
                                <FormLabel>Vantagens</FormLabel>
                                <Textarea
                                    name="vantagens"
                                    value={item.vantagens}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={200}
                                    resize={'none'}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem colSpan={12}>
                            <FormControl id="observacao">
                                <FormLabel>Observação</FormLabel>
                                <Textarea
                                    name="observacao"
                                    value={item.observacao}
                                    onChange={(e) => handleChange(e)}
                                    maxLength={200}
                                    resize={'none'}
                                />
                            </FormControl>
                        </GridItem>

                        <GridItem colSpan={3}>
                            <FormControl id="exibirOnline">
                                <FormLabel>
                                    Exibir Online
                                    <Tooltip
                                        label="Aparecer no site da Cardassi ou guia de conveniados"
                                        fontSize="md"
                                    >
                                        <InfoIcon ml={2} />
                                    </Tooltip>
                                </FormLabel>
                                <Switch
                                    name="exibirOnline"
                                    defaultChecked
                                    value={item.exibirOnline}
                                    onChange={(e) => {
                                        item.exibirOnline =
                                            item.exibirOnline === 0 ? 1 : 0;
                                        console.log(
                                            'ExibirOnline',
                                            item.exibirOnline
                                        );
                                    }}
                                />
                            </FormControl>
                        </GridItem>
                        {!conveniado && (
                            <GridItem colSpan={3}>
                                <FormControl id="senha">
                                    <FormLabel>Senha</FormLabel>
                                    <Input
                                        name="senha"
                                        value={item.senha}
                                        onChange={(e) => handleChange(e)}
                                        maxLength={20}
                                        type="password"
                                    />
                                </FormControl>
                            </GridItem>
                        )}
                        <GridItem colSpan={12}>
                            <Text fontWeight={'semibold'}>
                                Caro conveniado, essa autorização tem renovação
                                anual e automática.
                            </Text>
                            <Text fontWeight={'semibold'}>
                                Alterações de endereço, telefones e o
                                cancelamento desta autorização, deverão ser
                                feitos diretamente por esse site.
                            </Text>
                            {conveniado === undefined && (
                                <Box display={'flex'} mt={6}>
                                    <Checkbox
                                        isChecked={aceite}
                                        onChange={(e) =>
                                            setAceite(e.target.checked)
                                        }
                                        size={'lg'}
                                        mr={6}
                                    ></Checkbox>
                                    <Text fontWeight={'semibold'}>
                                        DECLARO para os devidos fins de direito
                                        e a quem possa interessar que o presente
                                        CADASTRO trata apenas de solicitação
                                        para divulgação de prestação de
                                        serviços, sem qualquer relação jurídica
                                        para com Vossa Senhoria, que fica isenta
                                        de quaisquer ônus independente de
                                        atendimento ou não da presente
                                        solicitação.
                                    </Text>
                                </Box>
                            )}
                        </GridItem>
                    </Grid>
                    {erro ? (
                        <Box color="red" fontWeight="bold">
                            <Text>{erro}</Text>
                        </Box>
                    ) : null}
                    <Button
                        mt={4}
                        mr={4}
                        isDisabled={
                            loading ||
                            (conveniado === undefined && aceite === false)
                        }
                        onClick={handleCadastro}
                        w="150px"
                    >
                        {loading && <Loading />}
                        {conveniado && <span>Salvar</span>}
                        {!conveniado && <span>Cadastrar</span>}
                    </Button>
                    {!conveniado && (
                        <Button
                            mt={4}
                            onClick={() => window.location.replace('/')}
                            w="150px"
                            disabled={loading}
                        >
                            <span>Voltar</span>
                        </Button>
                    )}
                </Box>
            </Flex>
        </Base>
    );
};

export default FormCadastro;
