import { baseApi } from './baseApi';

const apiWithTag = baseApi.enhanceEndpoints({
    addTagTypes: ['Especialidades'],
});

export const conveniadoApi = apiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        getEspecialidades: builder.query<OpcoesSelect[], void>({
            query() {
                let url = `conveniados/especialidades`;
                return {
                    url,
                };
            },
            providesTags: (result) => ['Especialidades'],
            transformResponse: (response: respostaEspecialidades[]) => {
                let retorno: OpcoesSelect[] = [];
                response.map((item) =>
                    retorno.push({ value: item.id, label: item.descricao })
                );
                return retorno;
            },
        }),
        getCEP: builder.query<retornoCEP, string>({
            query(cep) {
                let url = `cep/${cep}`;
                return {
                    url,
                };
            },
        }),
        getConveniado: builder.query<retornoConveniado, string>({
            query(id) {
                let url = `auth/conveniados/${id}`;
                return {
                    url,
                    //credentials: 'include',
                };
            },
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetEspecialidadesQuery,
    useLazyGetCEPQuery,
    useLazyGetConveniadoQuery,
} = conveniadoApi;
